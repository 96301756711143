<template>
  <div class="py-md-8 py-6">
    <v-form v-model="formIsValid" @submit.prevent="handleFormSubmit"
            ref="kitchenDetailsForm" lazy-validation>
      <v-row>
        <v-col>
          <label class="text-subtitle-2 gray--text text--darken-2 d-inline-block mb-3">
            {{ $t("createOrder.kitchenDetails.form.cutouts.label") }}
          </label>
          <div class="cutout-types mb-8">
            <template v-for="cutoutType in cutoutTypes">
              <v-checkbox v-model="form.cutouts" hide-details="auto" class="image-radio"
                          :key="cutoutType.id" :value="cutoutType" :value-comparator="compareCutoutTypes">
                <template v-slot:label>
                  <div class="d-flex flex-column align-center">
                    <img :src="getAttachmentPreviewURL(cutoutType.image)" :alt="cutoutType.displayName" />
                    <span class="d-inline-block mt-1 text--subtitle-2">{{ cutoutType.displayName }}</span>
                  </div>
                </template>
              </v-checkbox>
            </template>
            <v-checkbox
              hide-details
              :label="$t('createOrder.kitchenDetails.form.cutouts.noCutoutLabel')"
              value="NO_CUTOUT"
              v-model="form.noCutout"
              class="image-radio"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="shouldShowNumberOfOutlets">
        <v-col lg="2" md="6">
          <v-currency-field
            class="mt-1 mb-4 number-of-outlets-input"
            :value-as-integer="true"
            :decimal-length="0"
            :label="$t('createOrder.kitchenDetails.form.numberOfOutlets.label')"
            :rules="rules.numberOfOutlets"
            v-model="form.numberOfOutlets"
            required
            outlined
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <label class="text-subtitle-2 gray--text text--darken-2 d-inline-block mb-3">
            {{ $t("createOrder.kitchenDetails.form.hasOldCountertopRemoval.label") }}
          </label>
          <v-radio-group hide-details="auto" class="old-countertop-removal-options mb-5" row :rules="rules.hasOldCountertopRemoval" v-model="form.hasOldCountertopRemoval">
            <v-radio active-class="active" class="image-radio" :value="true" :label="$t('defaults.binaryQuestionAnswers.yes')" />
            <v-radio active-class="active" class="image-radio" :value="false" :label="$t('defaults.binaryQuestionAnswers.no')" />
          </v-radio-group>
        </v-col>
      </v-row>
      <div class="mt-2 action-buttons">
        <v-btn
          type="button"
          elevation="0"
          class="mr-4 rounded-0"
          outlined
          @click="goToPreviousStep"
          large
        >
          {{ $t("defaults.back") }}
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!formIsFilled || !formIsValid"
          elevation="0"
          color="primary"
          class="rounded-0"
          large
        >
          {{ $t("defaults.continue") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getAttachmentPreviewURL } from "@/utils/attachment";

export default {
  data() {
    return {
      formIsValid: true,
      form: {
        cutouts: [],
        hasOldCountertopRemoval: null,
        numberOfOutlets: null,
        noCutout: null
      },
      rules: {
        cutouts: [(v => v.length > 0 || this.form.noCutout !== null) || this.$t("createOrder.kitchenDetails.form.cutouts.rules.required")],
        hasOldCountertopRemoval: [v => v !== null || this.$t("createOrder.kitchenDetails.form.hasOldCountertopRemoval.rules.required")],
        numberOfOutlets: [
          v => v !== null || this.$t("createOrder.kitchenDetails.form.numberOfOutlets.rules.required"),
          v => v >= 1 || this.$t("createOrder.kitchenDetails.form.numberOfOutlets.rules.range")
        ]
      }
    };
  },
  watch: {
    ["form.cutouts"](cutouts) {
      if (cutouts.length > 0) {
        this.form.noCutout = null;
        this.saveSelectedCutouts(this.getCutoutsWithQuantity(cutouts));
      }
    },
    ["form.noCutout"](noCutout) {
      if (noCutout !== null) {
        this.form.cutouts = [];
        this.resetSelectedCutouts();
      }
    },
    ["form.hasOldCountertopRemoval"](hasOldCountertopRemoval) {
      this.saveHasOldCountertopRemoval(hasOldCountertopRemoval);
    },
    ["form.numberOfOutlets"]() {
      this.saveSelectedCutouts(this.getCutoutsWithQuantity(this.form.cutouts));
    }
  },
  computed: {
    ...mapState("KitchenDetails", {
      cutoutTypes: state => state.cutoutTypes,
      selectedCutouts: state => state.selectedCutouts,
      hasOldCountertopRemoval: state => state.hasOldCountertopRemoval
    }),
    shouldShowNumberOfOutlets() {
      return this.form.cutouts.some((cutout) => cutout.canBeMoreThanOne && cutout.name === this.$constants.OUTLET_CUTOUT_NAME);
    },
    formIsFilled() {
      return (this.form.noCutout || this.form.cutouts.length) && this.form.hasOldCountertopRemoval !== null && (this.shouldShowNumberOfOutlets ? this.form.numberOfOutlets !== null : true);
    }
  },
  methods: {
    getAttachmentPreviewURL,
    ...mapActions("Steps", ["completeStep"]),
    ...mapActions("KitchenDetails", [
      "getCutoutTypes", "saveSelectedCutouts", "resetSelectedCutouts", "saveHasOldCountertopRemoval"
    ]),
    goToPreviousStep() {
      this.$router.push("/design-countertop/visualization");
    },
    getCutoutsWithQuantity(cutouts) {
      return cutouts.map(cutout => {
        let quantity = 1;
        if (cutout.canBeMoreThanOne && cutout.name === this.$constants.OUTLET_CUTOUT_NAME) {
          quantity = Number(this.form.numberOfOutlets);
        }
        return {
          ...cutout,
          quantity
        };
      });
    },
    handleFormSubmit() {
      const isValid = this.$refs.kitchenDetailsForm.validate();
      if (isValid) {
        this.completeStep("kitchenDetails");
        this.$router.push("/order-countertop/address");
      }
    },
    compareCutoutTypes(a, b) {
      return a.id === b.id;
    }
  },
  async mounted() {
    if (this.cutoutTypes.length === 0) {
      this.getCutoutTypes();
    }

    if (this.hasOldCountertopRemoval !== null) {
      this.form.hasOldCountertopRemoval = this.hasOldCountertopRemoval;
    }

    this.form.cutouts = [];

    if (this.selectedCutouts.length > 0) {
      this.form.cutouts = [...this.selectedCutouts];
      const outletCutout = this.selectedCutouts.find(cutout => cutout.canBeMoreThanOne && cutout.name === this.$constants.OUTLET_CUTOUT_NAME);
      if (outletCutout) {
        this.form.numberOfOutlets = outletCutout.quantity;
      }
    }
  }
};
</script>
